<template lang="pug">
.table
  .table__row
    span.cell.cell--header(
      v-for="(item, indexRow) in header"
      :key="indexRow"
    ) {{item}}

  .table__row(
    v-for="(row, indexRow) in items"
    :key="indexRow"
  )
    span.cell(
      v-for="(item, indexCell) in row"
      :key="indexCell"
    ) {{item}}
</template>

<script>
// import {
//   mapGetters,
//   mapActions
// } from 'vuex'

import {
  Button
} from '@/components/atoms'

export default {
  name: 'Table',
  props: {
    header: {
      type: Array,
      required: false,
      validator: value => value.length > 0,
      default: () => ['oi', 'tchau']
    },
    items: {
      type: Array,
      required: true,
      default: () => [['oi', 'tchau'], ['oi', 'tchau']]
    }
  },
  components: {
    Button
  }
}
</script>

<style lang="sass" scoped>
  .table
    font-size: 16px
    line-height: 30px
    letter-spacing: 0em
    overflow: auto
    &__row
      display: flex
      flex-direction: row

    .cell
      min-width: 80px
      padding: 8px
      width: 100%
      display: flex
      align-items: center
      justify-content: center
      border: 1px solid #9D9D9D
      background: #E5EFF2
      white-space: nowrap
      overflow: hidden
      min-width: 120px
      &--header
        background: #96B8C2
        font-size: 20px

</style>
